import * as React from "react";
import C3Chart from "react-c3js";

import * as Types from "../../lib/Types";
import * as Helpers from "../../lib/Helpers"

interface IProps {
    chartData: Types.ChartData
}

export const Chart: React.FC<IProps> = (props: IProps): React.ReactElement => {

    const chartRef: React.MutableRefObject<C3Chart> = React.useRef()
    const chartData: Types.ChartData = props.chartData;

    // On Chart Data Change, Reload Chart Data using C3 API: https://c3js.org/samples/data_load.html
    // Cannot just pass new data directly to rect-c3js, causes issues: https://github.com/bcbcarl/react-c3js/issues/15
    // Solution Reference: https://c3js.org/reference.html#api-load
    React.useEffect((): void => {
        chartRef.current.chart.load({
            ...chartData,
            unload: true,
        })
    }, [chartData])

    // console.log(chartData);

    return <C3ChartOnMount
        chartRef={chartRef}
        chartData={chartData}
    />
}

class C3ChartOnMount extends React.Component<{ chartRef: React.MutableRefObject<C3Chart>, chartData: Types.ChartData }> {

    // Only render OnMount
    shouldComponentUpdate = (): boolean => false

    render(): JSX.Element {

        return <C3Chart
            ref={this.props.chartRef}
            data={this.props.chartData}
            axis={{
                x: {
                    type: 'year',
                    tick: {
                        format: (v) => {
                            return `Year ${ v + 1 }`
                        }
                    }
                },
                y: {
                    label: 'Dollars',
                    tick: {
                        format: (v) => {
                            return Helpers.formatCurrency(v)
                        }
                    }
                }
            }}
            grid={{
                x: {
                    show: false
                },
                y: {
                    show: true
                }
            }}
        />
    }

}

export default Chart;