import * as React from "react";
import * as _ from "lodash";
import * as Types from "../lib/Types";
import {Form, Card, Row, Col, InputGroup, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import logger from "use-reducer-logger";

import ExportPDF from "./CalculatorApp/ExportPDF";
import OutputBox from "./CalculatorApp/OutputBox";
import Chart from "./CalculatorApp/Chart";

import {reducer, initialState, AppContext} from "./CalculatorApp/AppState";

import * as Helpers from "../lib/Helpers";

import Constants from "../lib/Constants";
import {ResultsTableData} from "../lib/Types";
import {useEffect} from "react";

// https://stackoverflow.com/a/51583401
(global as any)._ = _;

const CalculatorApp: React.FC<{ debug: string }> = (props: { debug: string }): React.ReactElement => {

    const [state, dispatch] = React.useReducer(logger(reducer), initialState);

    useEffect(() => {

        console.log("useEffect");

    });

    // Event handlers

    const handleUnitsChange = (unitsValue: string): void => {

        console.log("handleUnitsChange");

        dispatch({
            type: "change_units",
            payload: unitsValue
        });
    }

    const handleFirstSelectChange = (uuid: string): void => {

        const productSelectItem: Types.ProductSelectItem = Helpers.itemForUuid(uuid);

        dispatch({
            type: "change_first_select",
            payload: productSelectItem
        });
    };

    const handleSecondSelectChange = (uuid: string): void => {

        const productSelectItem: Types.ProductSelectItem = Helpers.itemForUuid(uuid);

        dispatch({
            type: "change_second_select",
            payload: productSelectItem
        });
    };

    const handleLifeSpanManualInput = (inputValue: string): void => {

        const value = Helpers.formatNumericFromString(inputValue);

        dispatch({
            type: "change_life_span",
            payload: value
        });
    }

    const handleCostPerPadManualInput = (inputValue: string): void => {

        const value = Helpers.formatNumericFromString(inputValue);

        dispatch({
            type: "change_cost_per_pad",
            payload: value
        });
    }

    const handleCostPerPadDiamondBrushManualInput = (inputValue: string): void => {

        const value = Helpers.formatNumericFromString(inputValue);

        dispatch({
            type: "change_diamond_brush_cost_per_pad",
            payload: value
        });
    }

    const handleLifeSpanDiamondBrushManualInput = (inputValue: string): void => {

        const value = Helpers.formatNumericFromString(inputValue);

        dispatch({
            type: "change_diamond_brush_life_span",
            payload: value
        });
    }

    const handleUpdateCleansPerWeek = (inputValue: string): void => {

        const value = Helpers.formatNumericFromString(inputValue);

        dispatch({
            type: "change_cleans_per_week",
            payload: value
        });
    }

    const handleUpdateAreaPerClean = (inputValue: string): void => {

        const value = Helpers.formatNumericFromString(inputValue);

        dispatch({
            type: "change_area_per_clean",
            payload: value
        });
    }

    // Rendering

    const renderFirstSelectItems = (): React.ReactElement[] => {

        return _.map(Constants.firstProductSelectItems, (item: Types.ProductSelectItem, index: number): React.ReactElement => {
            return <option key={`${index}`} value={item.uuid}>
                {item.label}
            </option>;
        });
    };

    const renderSecondSelectItems = (): React.ReactElement[] => {

        return _.map(Constants.secondProductSelectItems, (group: Types.ProductSelectItemGroup, index: number): React.ReactElement => {
            return <optgroup label={group.label} key={`${index}`}>
                {
                    _.map(group.items, (item: Types.ProductSelectItem, index: number): React.ReactElement => {
                        return <option key={`${index}`} value={item.uuid}>
                            {item.label}
                        </option>
                    })
                }
            </optgroup>;
        });
    };

    const renderResultsTable = (resultsTableData: ResultsTableData): React.ReactElement => {

        return <table className={"table"}>
            <thead>
            <tr>
                <th style={{width: "20%"}}>
                    Year
                </th>
                <th>
                    Total Estimated Savings
                </th>
            </tr>
            </thead>

            <tbody>
            {_.map(resultsTableData.savingsPerYear, (row) => {

                return <tr key={`${row.year}`}>
                    <td>
                        {row.year}
                    </td>
                    <td>
                        {row.savingsCurrency}
                    </td>
                </tr>

            })}
            </tbody>

        </table>;
    }

    return (
        <AppContext.Provider value={{state, dispatch}}>

            <div>

                <Row className={"mb-4"}>
                    <Col md={8}>

                        <h4>
                            Scotch-Brite™ Diamond Floor Brush
                        </h4>

                    </Col>
                    <Col md={4} className={"text-right"}>

                        <ExportPDF
                            appState={state}
                            enabled={state.firstProductSelectItem !== null && state.secondProductSelectItem !== null}
                        />

                    </Col>
                </Row>

                <Card
                    className={"mb-4 shadow-sm"}
                >
                    <Card.Body>

                        <Row>
                            <Col>
                                <h5 className={"mb-4"}>
                                    Units
                                </h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col>

                                <ToggleButtonGroup
                                    onChange={handleUnitsChange}
                                    type={"radio"}
                                    name={"units"}
                                    defaultValue={"imperial"}
                                >
                                    <ToggleButton value={"imperial"}>Imperial</ToggleButton>
                                    <ToggleButton value={"metric"}>Metric</ToggleButton>
                                </ToggleButtonGroup>

                            </Col>
                        </Row>

                    </Card.Body>
                </Card>

                <Card
                    className={"mb-4 shadow-sm"}
                    border={""}
                >

                    <Card.Body>

                        <Row>
                            <Col>
                                <h5 className={"mb-4"}>
                                    Inputs
                                </h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>

                                <Form.Group controlId="">

                                    <Form.Label>
                                        What are you using today to clean your uncoated concrete floors?
                                    </Form.Label>

                                    <Form.Control
                                        as="select"
                                        defaultValue={""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            console.log(event.target.value);
                                            handleFirstSelectChange(event.target.value);
                                        }}

                                    >

                                        <option value=""></option>
                                        {renderFirstSelectItems()}

                                    </Form.Control>
                                </Form.Group>

                            </Col>
                            <Col md={6}>

                                <Row>

                                    <Col>

                                        <Form.Group>

                                            <Form.Label>
                                                Estimated Life Span
                                            </Form.Label>

                                            <InputGroup>

                                                <Choose>
                                                    <When condition={state.units == "metric"}>

                                                        <Form.Control
                                                            type={"text"}
                                                            // defaultValue={""}
                                                            value={state.firstProductSelectItem ? state.firstProductSelectItem.lifeSpanMetric : 0}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                                handleLifeSpanManualInput(event.target.value);
                                                            }}
                                                        >
                                                        </Form.Control>

                                                        <InputGroup.Append>

                                                            <InputGroup.Text id="">Sq m.</InputGroup.Text>

                                                        </InputGroup.Append>

                                                    </When>
                                                    <Otherwise>

                                                        <Form.Control
                                                            type={"text"}
                                                            // defaultValue={""}
                                                            value={state.firstProductSelectItem ? state.firstProductSelectItem.lifeSpan : 0}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                                handleLifeSpanManualInput(event.target.value);
                                                            }}
                                                        >
                                                        </Form.Control>

                                                        <InputGroup.Append>

                                                            <InputGroup.Text id="">Sq ft.</InputGroup.Text>

                                                        </InputGroup.Append>

                                                    </Otherwise>
                                                </Choose>

                                            </InputGroup>


                                            <Choose>
                                                <When condition={state.units == "metric"}>

                                                    <Form.Text className="text-muted">
                                                        Field defaults to 0, type in the estimated square meters the pad/brush cleans
                                                    </Form.Text>

                                                </When>
                                                <Otherwise>

                                                    <Form.Text className="text-muted">
                                                        Field defaults to 0, type in the estimated square feet the pad/brush cleans
                                                    </Form.Text>

                                                </Otherwise>
                                            </Choose>

                                        </Form.Group>

                                    </Col>

                                    <Col>

                                        <Form.Group>

                                            <Form.Label>
                                                Price Paid per Pad/Brush
                                            </Form.Label>

                                            <InputGroup>

                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="">$</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control
                                                    type={"text"}
                                                    // defaultValue={""}
                                                    value={state.firstProductSelectItem ? state.firstProductSelectItem.costPerPad : 0}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                        handleCostPerPadManualInput(event.target.value);
                                                    }}
                                                >

                                                </Form.Control>

                                            </InputGroup>

                                            <Form.Text className="text-muted">
                                                Field defaults to 0, type in the dollar amount
                                            </Form.Text>

                                        </Form.Group>

                                    </Col>

                                </Row>

                            </Col>
                        </Row>

                        <Row className={"mt-2"}>

                            <Col md={6}>

                                <Form.Group controlId="">

                                    <Form.Label>
                                        Select Scotch-Brite™ Diamond Floor Brush
                                    </Form.Label>

                                    <Form.Control
                                        as="select"
                                        defaultValue={""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            handleSecondSelectChange(event.target.value);
                                        }}

                                    >
                                        <option value=""></option>
                                        {renderSecondSelectItems()}
                                    </Form.Control>

                                </Form.Group>

                            </Col>
                            <Col>

                                <Row>

                                    <Col>

                                        <Form.Group>

                                            <Form.Label>
                                                Estimated Life Span*
                                            </Form.Label>

                                            <InputGroup>

                                                <Choose>
                                                    <When condition={state.units == "metric"}>

                                                        <Form.Control
                                                            type={"text"}
                                                            readOnly={true}
                                                            // defaultValue={""}
                                                            value={state.secondProductSelectItem ? state.secondProductSelectItem.lifeSpanMetric : 0}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                                handleLifeSpanManualInput(event.target.value);
                                                            }}
                                                        >
                                                        </Form.Control>

                                                        <InputGroup.Append>

                                                            <InputGroup.Text id="">Sq m.</InputGroup.Text>

                                                        </InputGroup.Append>

                                                    </When>
                                                    <Otherwise>

                                                        <Form.Control
                                                            type={"text"}
                                                            readOnly={true}
                                                            // defaultValue={""}
                                                            value={state.secondProductSelectItem ? state.secondProductSelectItem.lifeSpan : 0}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                                handleLifeSpanDiamondBrushManualInput(event.target.value);
                                                            }}
                                                        >
                                                        </Form.Control>

                                                        <InputGroup.Append>

                                                            <InputGroup.Text id="">Sq ft.</InputGroup.Text>

                                                        </InputGroup.Append>

                                                    </Otherwise>
                                                </Choose>

                                            </InputGroup>

                                            <Form.Text className="text-muted">
                                                * based on average usage.
                                            </Form.Text>

                                        </Form.Group>

                                    </Col>

                                    <Col>

                                        <Form.Group>

                                            <Form.Label>
                                                Estimated Price per Brush
                                            </Form.Label>

                                            <InputGroup>

                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="">$</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control
                                                    type={"text"}
                                                    // defaultValue={""}
                                                    value={state.secondProductSelectItem ? state.secondProductSelectItem.costPerPad : 0}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                        handleCostPerPadDiamondBrushManualInput(event.target.value);
                                                    }}
                                                >
                                                </Form.Control>

                                            </InputGroup>

                                            <Form.Text className="text-muted">
                                                Field defaults to 0, type in the dollar amount
                                            </Form.Text>

                                        </Form.Group>

                                    </Col>

                                </Row>

                            </Col>
                        </Row>

                        <Row className={"mt-4"}>
                            <Col md={4}>

                                <Form.Group>

                                    <Form.Label>
                                        How often do you clean?
                                    </Form.Label>

                                    <InputGroup>

                                        <Form.Control
                                            type={"number"}
                                            min={1}
                                            defaultValue={state.cleansPerWeek}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                handleUpdateCleansPerWeek(event.target.value);
                                            }}
                                        >
                                        </Form.Control>

                                        <InputGroup.Append>
                                            <InputGroup.Text id="">Times Per week</InputGroup.Text>
                                        </InputGroup.Append>

                                    </InputGroup>

                                </Form.Group>

                            </Col>
                            <Col md={4}>

                                <Form.Group>

                                    <Form.Label>
                                        Area cleaned
                                    </Form.Label>

                                    <InputGroup>

                                        <Form.Control
                                            type={"text"}
                                            min={1}
                                            defaultValue={state.areaPerClean}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                handleUpdateAreaPerClean(event.target.value);
                                            }}
                                        >
                                        </Form.Control>

                                        <InputGroup.Append>

                                            <Choose>
                                                <When condition={state.units == "metric"}>
                                                    <InputGroup.Text id="">Sq m.</InputGroup.Text>
                                                </When>
                                                <Otherwise>
                                                    <InputGroup.Text id="">Sq ft.</InputGroup.Text>
                                                </Otherwise>
                                            </Choose>

                                        </InputGroup.Append>

                                    </InputGroup>

                                    <Choose>
                                        <When condition={state.units == "metric"}>

                                            <Form.Text className="text-muted">
                                                Field defaults to 10000, type in the square meters of the area being cleaned
                                            </Form.Text>

                                        </When>
                                        <Otherwise>

                                            <Form.Text className="text-muted">
                                                Field defaults to 10000, type in the square feet of the area being cleaned
                                            </Form.Text>

                                        </Otherwise>
                                    </Choose>

                                </Form.Group>

                            </Col>
                        </Row>

                    </Card.Body>

                </Card>

                <Card className={"shadow-sm"}>
                    <Card.Body>

                        <h5 className={"mb-4"}>
                            Cost Comparison / Estimated Savings
                        </h5>

                        <Row>
                            <Col md={4}>

                                <OutputBox
                                    label={"Current"}
                                    value={`${state.resultsTableData.costCurrentCurrency}`}
                                />

                            </Col>
                            <Col md={4}>

                                <OutputBox
                                    label={"Scotch-Brite™ Diamond Floor Brush"}
                                    value={`${state.resultsTableData.costDiamondBrushCurrency}`}
                                />

                            </Col>
                            <Col md={4}>

                                <OutputBox
                                    label={"Estimated Annual Savings"}
                                    value={`${state.resultsTableData.savingsCurrency}`}
                                />

                            </Col>
                        </Row>

                        <div className={"mt-4"}>

                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th style={{width: "50%"}}>

                                        </th>
                                        <th>

                                            <Choose>
                                                <When condition={state.units == "metric"}>
                                                    Cost / Area (100 Sq m.)
                                                </When>
                                                <Otherwise>
                                                    Cost / Area (1000 sq. ft.)
                                                </Otherwise>
                                            </Choose>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <strong>Current</strong>
                                    </td>
                                    <td>
                                        {state.resultsTableData.costAreaCurrent}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Scotch-Brite™ Diamond Floor Brush</strong>
                                    </td>
                                    <td>
                                        {state.resultsTableData.costAreaDiamondBrush}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className={"mt-4"}>

                            {renderResultsTable(state.resultsTableData)}

                        </div>


                        <If condition={state.chartData}>

                            <div className={"mt-4"}>

                                <Chart
                                    chartData={state.chartData}
                                />

                            </div>

                        </If>

                    </Card.Body>
                </Card>

            </div>
        </AppContext.Provider>);
};

export default CalculatorApp;