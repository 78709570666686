import * as React from "react";
import * as Types from "../../lib/Types";
import * as Helpers from "../../lib/Helpers";

const initialState: Types.AppState = {
    units: "imperial",
    firstProductSelectItem: null,
    secondProductSelectItem: null,
    firstProductCostPerSqFt: 0,
    secondProductCostPerSqFt: 0,
    cleansPerWeek: 1,
    areaPerClean: 10000,
    cleanSqFtPerYear: Helpers.calcCleanSqFtPerYear(1, 10000),
    resultsTableData: {
        costCurrentCurrency: "",
        costDiamondBrushCurrency: "",
        savings: 0,
        savingsCurrency: "",
        savingsPerYear: [],
        costAreaCurrent: "",
        costAreaDiamondBrush: ""
    },
    chartData: null
}

const reducer = (state: Types.AppState, action: Types.AppStateAction): Types.AppState => {
    switch (action.type) {
        case "change_units":

            return Helpers.stateWithNewCalcs({
                ...state,
                units: action.payload
            });
        case "change_first_select":

            return Helpers.stateWithNewCalcs({
                ...state,
                firstProductSelectItem: action.payload
            });
        case "change_second_select":

            return Helpers.stateWithNewCalcs({
                ...state,
                secondProductSelectItem: action.payload
            });
        case "change_life_span":

            return Helpers.stateWithNewCalcs({
                ...state,
                firstProductSelectItem: {
                    ...state.firstProductSelectItem,
                    lifeSpan: action.payload
                }
            })
        case "change_cost_per_pad":

            return Helpers.stateWithNewCalcs({
                ...state,
                firstProductSelectItem: {
                    ...state.firstProductSelectItem,
                    costPerPad: action.payload
                }
            })
        case "change_diamond_brush_life_span":

            return Helpers.stateWithNewCalcs({
                ...state,
                secondProductSelectItem: {
                    ...state.secondProductSelectItem,
                    lifeSpan: action.payload
                }
            })
        case "change_diamond_brush_cost_per_pad":

            return Helpers.stateWithNewCalcs({
                ...state,
                secondProductSelectItem: {
                    ...state.secondProductSelectItem,
                    costPerPad: action.payload
                }
            })
        case "change_cleans_per_week":

            return Helpers.stateWithNewCalcs({
                ...state,
                cleansPerWeek: action.payload,
                cleanSqFtPerYear: Helpers.calcCleanSqFtPerYear(action.payload, state.areaPerClean)
            })
        case "change_area_per_clean":

            return Helpers.stateWithNewCalcs({
                ...state,
                areaPerClean: action.payload,
                cleanSqFtPerYear: Helpers.calcCleanSqFtPerYear(state.cleansPerWeek, action.payload)
            })
        default:
            throw new Error();
    }
};

const AppContext: React.Context<Types.ContextType | null> = React.createContext(null);

export {reducer, initialState, AppContext};