import * as React from "react";

import * as Types from "../../lib/Types"

interface IProps {
    label: string
    value: string
    class?: string
}

const OutputBox: React.FC<IProps> = (props: IProps): React.ReactElement => {

    return(
        <div className={`${ props.class } output-box`}>
            <small><strong>{props.label}</strong></small>
            <br/>
            <Choose>
                <When condition={props.value}>
                    {props.value}
                </When>
                <Otherwise>
                --
                </Otherwise>
            </Choose>
        </div>
    )
}

export default OutputBox;