import * as Types from "./Types";

class Constants {

    productSelectItems: Types.ProductSelectItemList;
    firstProductSelectItems: Types.ProductSelectItemList;
    secondProductSelectItems: Types.ProductSelectItemGroupList;
    applicationStrings: {};

    constructor() {

        this.productSelectItems = [
            // First group
            {
                groupKey: "1",
                uuid: "ecd818c5-37df-478a-95b3-8442916df331",
                label: "Regular Floor Pads",
                lifeSpan: 1000,
                costPerPad: 1
            },
            {
                groupKey: "1",
                uuid: "66754587-abfc-4089-9b4e-223ad0d00a61",
                label: "Diamond Floor Pads",
                lifeSpan: 1000,
                costPerPad: 1
            },
            {
                groupKey: "1",
                uuid: "3bf29d5f-881f-4262-bde6-6483e61fc8c9",
                label: "Bristle or Nylon Floor Brush",
                lifeSpan: 1000,
                costPerPad: 1
            },
            {
                groupKey: "1",
                uuid: "b816f273-d36d-4a84-bcc4-a1dda4bd9ca6",
                label: "Rigid Diamond Blade System",
                lifeSpan: 1000,
                costPerPad: 1
            },
            // Second Group
            {
                groupKey: "2",
                uuid: "15055be5-762e-47fa-9296-7c040905fb2c",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 13\" (33.02cm)",
                lifeSpan: 3500000,
                lifeSpanMetric: Math.round(3500000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "d8981e93-5236-411e-a9a9-19d79b1e69c5",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 14\" (35.56cm)",
                lifeSpan: 3500000,
                lifeSpanMetric: Math.round(3500000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "4f6c3558-2984-44b4-a5c0-d1793c4bc982",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 15\" (38.10cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "ef117078-e197-4ed6-a78f-c51e178c729d",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 16\" (40.64cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "258bf5ac-940c-447d-b0d4-9c828c921f64",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 17\" (43.18cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "9d2aaa9b-ac4e-4e02-9f65-d494d13609a4",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 18\" (45.72cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "21914ad2-db82-49b2-8f10-cacd68f3ef6d",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 19\" (48.26cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "2",
                uuid: "b28a2a4a-219e-44d3-8abc-5e8fa45dbf6c",
                label: "Scotch-Brite™ Diamond Floor Brush 1000 - 20\" (50.80cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "fbed9301-f249-4684-b9a7-799e05e69d9a",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 13\" (33.02cm)",
                lifeSpan: 3500000,
                lifeSpanMetric: Math.round(3500000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "28ebd62c-1a5d-4a7e-a18f-0364848c634c",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 14\" (35.56cm)",
                lifeSpan: 3500000,
                lifeSpanMetric: Math.round(3500000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "98bc1f4b-48fa-4045-bbcd-baeccad522ee",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 15\" (38.10cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "b954706a-2d4e-423d-8241-3316124a130b",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 16\" (40.64cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "43f73afb-2082-4348-a292-0bccd74c0dc8",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 17\" (43.18cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "2c88769d-16a6-44d3-b05a-20895aaecea2",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 18\" (45.72cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "4a9d55ae-5aa5-4201-8e2d-92a58b3d4ac8",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 19\" (48.26cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            },
            {
                groupKey: "3",
                uuid: "055af633-4d87-4625-90f3-fe92b990a741",
                label: "Scotch-Brite™ Diamond Floor Brush 3000 - 20\" (50.80cm)",
                lifeSpan: 5000000,
                lifeSpanMetric: Math.round(5000000 / 10.764),
                costPerPad: 0
            }
        ];

        this.firstProductSelectItems = _.filter(this.productSelectItems, {groupKey: "1"});

        this.secondProductSelectItems = [
            {
                label: "Scotch-Brite™ Diamond Floor Brush 1000",
                items: _.filter(this.productSelectItems, {groupKey: "2"})
            },
            {
                label: "Scotch-Brite™ Diamond Floor Brush 3000",
                items: _.filter(this.productSelectItems, {groupKey: "3"})
            }
        ];
    }
}

export default new Constants();