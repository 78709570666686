import numeral from "numeral";

import * as Types from "./Types";
import Constants from "./Constants";

export const itemForUuid = (uuid: string): Types.ProductSelectItem => {

    const result: Types.ProductSelectItem = _.find(Constants.productSelectItems, (p: Types.ProductSelectItem): boolean => {
        return p.uuid === uuid;
    });

    return result;
};

export const calcCleanSqFtPerYear = (cleansPerWeek: number, areaPerClean: number): number => {

    const weeksToCalc = 52;
    const result = cleansPerWeek * areaPerClean * weeksToCalc;

    return result;
}

export const stateWithNewCalcs = (appState: Types.AppState): Types.AppState => {

    // Only one is selected
    if (!_.isNil(appState.firstProductSelectItem) && _.isNil(appState.secondProductSelectItem)) {

        const firstProductCostPerSqFt = appState.firstProductSelectItem.costPerPad / appState.firstProductSelectItem.lifeSpan;

        return {
            ...appState,
            firstProductCostPerSqFt: firstProductCostPerSqFt
        }

    }
    // Both are selected
    else if (appState.firstProductSelectItem && appState.secondProductSelectItem) {

        let firstProductCostPerSqFt = appState.firstProductSelectItem.costPerPad / appState.firstProductSelectItem.lifeSpan;
        let secondProductCostPerSqFt = appState.secondProductSelectItem.costPerPad / appState.secondProductSelectItem.lifeSpan;

        if( appState.units == "metric"){

            // Converted units not needed since this is based on user input
            firstProductCostPerSqFt = appState.firstProductSelectItem.costPerPad / appState.firstProductSelectItem.lifeSpan;
            // User metric units from constants
            secondProductCostPerSqFt = appState.secondProductSelectItem.costPerPad / appState.secondProductSelectItem.lifeSpanMetric;
        }
        else {
            firstProductCostPerSqFt = appState.firstProductSelectItem.costPerPad / appState.firstProductSelectItem.lifeSpan;
            secondProductCostPerSqFt = appState.secondProductSelectItem.costPerPad / appState.secondProductSelectItem.lifeSpan;
        }

        const firstProductCost = firstProductCostPerSqFt * appState.cleanSqFtPerYear;
        const secondProductCost = secondProductCostPerSqFt * appState.cleanSqFtPerYear;

        let firstProductCostArea = 0;
        let secondProductCostArea = 0;

        if( appState.units == "metric"){
            firstProductCostArea = (appState.firstProductSelectItem.costPerPad / appState.firstProductSelectItem.lifeSpan) * 100;
            secondProductCostArea = (appState.secondProductSelectItem.costPerPad / appState.secondProductSelectItem.lifeSpanMetric) * 100;
        }
        else {
            firstProductCostArea = (appState.firstProductSelectItem.costPerPad / appState.firstProductSelectItem.lifeSpan) * 1000;
            secondProductCostArea = (appState.secondProductSelectItem.costPerPad / appState.secondProductSelectItem.lifeSpan) * 1000;
        }

        const savings = firstProductCost - secondProductCost;
        const savingsPerYear = _.map(_.times(5), (year) => {

            const currentYear = year + 1;
            const savingsForYear = savings * currentYear;

            return {
                year: currentYear,
                savings: savingsForYear,
                savingsCurrency: formatCurrency(savingsForYear)
            }
        });

        const tmpState = {
            ...appState,
            firstProductCostPerSqFt: firstProductCostPerSqFt,
            secondProductCostPerSqFt: secondProductCostPerSqFt,
            resultsTableData: {
                costCurrentCurrency: formatCurrency(firstProductCost),
                costDiamondBrushCurrency: formatCurrency(secondProductCost),
                savings: savings,
                savingsCurrency: formatCurrency(savings),
                savingsPerYear: savingsPerYear,
                costAreaCurrent: formatCurrency(firstProductCostArea),
                costAreaDiamondBrush: formatCurrency(secondProductCostArea)
            }
        }

        return {
            ...tmpState,
            chartData: formatChartData(tmpState)
        }
    }
    // Nothing selected
    else {

        return {
            ...appState
        }
    }
};

//  Formatters

export const formatNumericFromString = (stringValue: string): number => {

    if (stringValue === "") {
        stringValue = "0";
    }

    return numeral(stringValue).value();
}

export const formatSqFeet = (value: number): string => {
    return numeral(value).format('0,0');
}

export const formatCurrency = (value: number): string => {
    return numeral(value).format('$0,0.00');
}

export const formatChartData = (appState: Types.AppState): Types.ChartData => {

    const tmp = _.map(appState.resultsTableData.savingsPerYear, (year) => {
        return year.savings.toFixed(2);
    })

    console.log("formatChartData");
    console.log(appState.cleansPerWeek);
    console.log(tmp);

    return {
        type: 'bar',
        columns: [
            [
                'Total Savings',
                ...tmp
            ]
        ],
        labels: {
            format: (v, id, i, j) => {
                return `${formatCurrency(v)}`
            }
        }
        // types: [],
        // axes: [],
        // colors: []
    }
}